.contact {
    position: relative;
    background-position: center;
    background-size: cover;
      
    .img {
        padding-bottom: 34rem;
        width: 100%;
        display: block;

        @media (max-width: 900px) {
            padding-bottom: 38rem;
        }

        @media (max-width: 600px) {
            padding-bottom: 47rem;
        }
    }

    .content-container {
        width: fit-content;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);

        @media (max-width: 600px) {
            padding-top: 0;
            width: 100%;
            max-width: 100%;
        }

        .content {
            padding: 4rem 2rem;
            text-align: center;
            gap: 2rem;
            background-color: white;

            @media (max-width: 600px) {
                padding: 2rem 1rem;
            }

            .cols {
                gap: 2rem;

                @media (max-width: 600px) {
                    grid-template-columns: 1fr;
                    
                }

                .left {
                    text-align: left;
                    gap: 1rem;

                    @media (max-width: 600px) {
                        text-align: center;
                    }
                }

                .right {
                    text-align: left;
                    gap: 1rem;

                    input, textarea {
                        padding: 0.25rem;
                        width: 96%;
                    }
                    
                    textarea {
                        height: 6rem;
                        resize: vertical;
                    }
                }
            }
        }
    }
}