.projects {
    padding: 4rem 0;
    display: block;
    color: white;
    background-position: center;
    background-size: cover;

    .content {
        margin: 0 auto;
        width: 60vw;
        gap: 2rem;
        text-align: center;


        .projects-list {

            @media (max-width: 900px) {
                grid-template-columns: 1fr 1fr;
                row-gap: 2rem;
            }

            @media (max-width: 600px) {
                grid-template-columns: 1fr;
                width: 70vw;
                gap: 2rem;
            }

            

            .project {
                position: relative;
                width: 100%;
                overflow: hidden;
                background-color: black;

                @media (max-width: 900px) {
                    background-color: transparent;
                }

                .text {
                    position: absolute;
                    width: 90%;
                    top: 50%;
                    left: 50%;  
                    transform: translate(-50%, -50%);
                    text-align: center;

                    @media (max-width: 900px) {
                        padding-top: 0.6rem;
                        position: relative;
                        top: auto;
                        left: auto;
                        transform: translate(0);
                    }
                }

                img {
                    width: 100%;
                    display: block;
                    transition: 0.4s;
                }

                p {
                    opacity: 0;

                    @media (max-width: 900px) {
                        opacity: 1;
                    }
                }

                &:hover {
                    cursor: pointer;

                    img {
                        transform: scale(1.2);
                        opacity: 0.4;

                        @media (max-width: 900px) {
                            transform: none;
                            opacity: 1;
                        }
                    }

                    p {
                        opacity: 1;
                    }
                }
            }
        }
    }
}