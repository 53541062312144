.divider-white {
    margin: 0 auto;
    width: 4rem;
    height: 2px;
    background-color: white;
}
.divider-black {
    margin: 0 auto;
    width: 4rem;
    height: 2px;
    background-color: black;
}