.skills-list {
    display: block;
    background-position: center;
    background-size: cover;

    * {
        color: white;
    }

    .content {
        margin: 0 auto;
        padding: 4rem 0;
        width: 60vw;
        text-align: center;
        gap: 2rem;

        @media (max-width: 600px) {
            width: 80vw;
        }

        .badges {
            gap: 4rem;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            @media (max-width: 600px) {
                gap: 2rem;
                grid-template-columns: none;
                grid-template-rows: 1fr 1fr 1fr;
            }

            .badge {

                img {
                    margin-bottom: 1rem;
                    height: 6rem;
                }
            }
        }
    }
}