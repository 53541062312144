.footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    color: white;
    background-position: center;
    background-size: cover;

    .content {
        padding: 2rem;
        width: fit-content;
        line-height: 1.6;
    }
}