.details {
    --img-grid-width: 70vw;

    padding: 2rem;

    .content {
        margin-bottom: 12rem;

        .title {
            margin-bottom: 2rem;
        }

        .body {
            align-items: flex-start;
            gap: 2rem;

            @media (max-width: 600px) {
                flex-direction: column-reverse;
            }

            .left {
                display: inline-block;
                width: var(--img-grid-width);

                @media (max-width: 600px) {
                    margin: 0 auto;
                    width: 100%;
                }

                .image-container {
                    width: 100%;
                    display: block;
                    position: relative;

                    .prev {
                        left: 1rem;
                    }

                    .next {
                        right: 1rem;
                    }

                    button {
                        max-width: 2rem;
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);

                        &:hover {
                            cursor: pointer;
                        }

                        @media (max-width: 900px) {
                            width: 1rem;
                        }
                    }

                    img {
                        max-width: 100%;
                        display: block;
                    }
                }

                .images-container {
                    margin-top: 2rem;
                    width: 100%;

                    .images {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        gap: 1rem;

                        img {
                            width: 100%;
    
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .right {
                display: inline-block;
                width: calc(100vw - var(--img-grid-width));

                @media (max-width: 600px) {
                    width: 100%;
                }

                .caption {
                    margin-top: 2rem;
                    gap: 0.8rem;
                    

                    @media (max-width: 600px) {
                        margin-top: 0;
                    }

                    p {
                        font-size: 14pt;
                    }
                }
            }
        }
    }
}