.header {
    --header-height: 12rem;
    --mobile-height: 60vh;
    --mobile-header-height: 6.4rem;

    display: block; 

    @media (max-width: 600px) {
        max-height: 60vh;
    }

    .banner {
        height: var(--header-height);
        display: block;
        position: relative;
        background-position: center;
        background-size: cover;

        @media (max-width: 600px) {
            height: 6.4rem;
        }

        .logo {
            height: 12rem;
            width: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @media (max-width: 600px) {
                height: 5.6rem;
                left: 1rem;
                transform: translate(0, -50%);
            }
        }

        .socials {
            position: absolute;
            top: 1rem;
            right: 2rem;
            width: 5rem;
        
            @media (max-width: 600px) {
                right: 1rem;
                top: 50%;
                transform: translate(0, -50%);
            }

            img {
                display: block;
                height: 1.8rem;
            }
        }
    }    

    .slider {
        width: 100%;
        height: calc(100vh - var(--header-height));
        display: block;

        @media (max-width: 600px) {
            height: calc(var(--mobile-height) - var(--mobile-header-height));
        }
    }
}