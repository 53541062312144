.img-slider {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    .img {
        height: 100%;
        width: 100%;
        position: absolute;
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .prev {
        left: -100%;
    }

    .next {
        left: 100%;
    }

    /* ---------- ANIMATION-CLASSES ---------- */
    .img-slide-prev {
        transform: translate(100%, 0);
    }
    .img-slide-next {
        transform: translate(-100%, 0);
    }

    .arrows {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 98%;
        transform: translate(-50%, -50%);

        img {
            height: 3rem;

            &:hover {
                cursor: pointer;
            }
        }
    }
}