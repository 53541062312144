/* ---------->>> ROWS <<<---------- */
.row {
    display: flex;
    flex-direction: row;
}
.row-c-c {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.row-sa-c {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.row-sb-c {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.row-se-c {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.row-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* ---------->>> COLUMNS <<<---------- */
.col {
    display: flex;
    flex-direction: column;
}
.col-c-c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* ---------->>> GRIDS <<<---------- */
.grid-2col-1row {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.grid-3col-1row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

/* ---------->>> SIZING <<<---------- */
.full-sw-sh {
    display: flex;
    width: 100vw;
    height: 100vh;
}
.full-w {
    width: 100%;
}

/* ---------->>> BUTTON-STYLES <<<---------- */
.btn {
    padding: 0.5rem 1rem;
    border: none;
    color: white;
    background-color: black;
    transition: all 0.4s;

    &:hover {
        cursor: pointer;
        box-shadow: 2px 2px 6px 0 rgba($color: #000000, $alpha: 0.6);
        transform: translate(-2px, -2px);
    }
}

/* ---------->>> IMAGE-STYLES <<<---------- */
.selected {
    opacity: 0.4;
}
.clear {
    border: none;
    background-color: transparent;
}