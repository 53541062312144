.about {
    padding: 4rem 0;
    background-position: center;
    background-size: cover;

    .content-container {
        margin: 0 auto;
        width: 60vw;
        background-color: white;
        text-align: center;
        
        @media (max-width: 600px) {
            width: 75vw;
        }

        .content {
            padding: 4rem;
            gap: 2rem;
            // line-height: 1.6rem;
            // letter-spacing: 0.02rem;

            @media (max-width: 600px) {
                padding: 4rem 2rem;
            }
        }
    }
}