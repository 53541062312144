.project-header {
    height: 6.4rem;
    display: block;
    position: relative;
    background-position: center;
    background-size: cover;

    .logo {
        height: 5.6rem;
        width: auto;
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translate(0, -50%);
    }

    .logo:hover {
        cursor: pointer;
    }

    .socials {
        position: absolute;
        right: 2rem;
        width: 5rem;
        top: 50%;
        transform: translate(0, -50%);
    
        @media (max-width: 600px) {
            right: 1rem;
        }

        img {
            display: block;
            height: 1.8rem;
        }
    }
} 